.a-fact {
  @extend %fw-medium;
  text-align: left;
  margin-bottom: rem(25);

  @include above($mobile) {
    text-align: center;
    margin-bottom: rem(40);
  }

  @include below($mobile) {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__result {
    @extend %ff-sans;

    font-size:10vw;
    line-height: 1;
    color: $black;

    @include above($xsmall) {
      font-size:rem(50);
    }

    @include above($mobile) {
      white-space: nowrap;
    }
  }

  &__description {
    @extend %ff-serif;

    margin: 0;
    text-transform: uppercase;
    color: $grey-dark;

    $fs: 16;
    font-size: rem($fs);
    line-height: em(24, $fs);

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    @include above($xsmall) {
      $fs: 18;
      font-size: rem($fs);
      line-height: em(24, $fs);
    }
  }
}


#o-project--marketing-acties .a-fact__result {
  @include between($mobile, $large) {
    white-space: normal;
    line-height: 1;
    padding: .3em 0;
  }
}
