.a-logo {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: 7%;
  white-space: nowrap;

  a {
    text-decoration: none;
    &:after { display: none; }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 24px;
    top: -5px;
    height: 53.21px;
    width: 1px;
    background-color: $logo-color;
    transform: rotate(-20deg);
    transform-origin: top left;
}

  &__img {
    display: inline-block;
    vertical-align: middle;
    height: 40px;
    width: 25px;
    margin: 0;
    fill: $logo-color;
  }

  &__text {
    margin-left: 13px;
    display: inline-block;
    vertical-align: middle;
    line-height: 1.2;
    text-align: left;
  }

  &__title,
  &__tagline {
    display: block;
    color: $logo-color;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
  }

  &__tagline {
    font-size: 12px;
  }
}
