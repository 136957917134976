$template: '.template-3';

#{$template} {
  background-color: $yellow-light;
}

#{$template} .background {
  display: none;

  @include above($mobile) {
    display: block;
  }
}
#{$template}__content {
  .a-copy {
    margin: 0;
  }

  .a-subtitle {
    margin-top: 0;
  }

  @include above($mobile) {
    height: rem(800);
  }
}

.template-3-tabs {
  display: none;

  @include above($mobile) {
    display: flex;
    width: 100%;
    height: 100%;
  }
}

.template-3-accordion {
  display: block;

  @include above($mobile) {
    display: none;
  }
}

#{$template} {

  // .background .bg-img.bg-img--top-left:after {
  //   content: '';
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   background: #FFF;
  //   transition: all 0.6s 0.3s ease-out;
  // }

  // &.show .background .bg-img.bg-img--top-left:after {
  //   //height: 0;
  //   //top: 100%;
  //   bg-img__inner: 0;
  // }


  // .background .bg-img.bg-img--top-right:after {
  //   content: '';
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   border: 300px solid #FFF;
  //   border-top: 0;
  //   border-left: 0;
  //   transition: all 0.4s 0.3s ease-out;
  // }

  // &.show .background .bg-img.bg-img--top-right:after {
  //   //height: 0;
  //   //width: 0;
  //   //top: 100%;
  //   //left: 100%;
  //   border-width: 0px;
  // }
}
