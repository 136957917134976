.a-subproject-tabs {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  li {
    @extend %ff-serif;
    @extend %fw-medium;

    font-size: rem(20);
    position: absolute;
    width: 50%;
    text-align: center;
    height: rem(80);
    padding: 0 rem(15);
    cursor: pointer;
    line-height: 1.4;


    &:nth-child(1) {
      top: 50%;
      transform: translateY(-50%);
    }

    &:nth-child(2) {
      top: 50%;
      left: 50%;
      transform: translateY(-50%);
    }

    &:nth-child(3) {
      top: 75%;
      left: 25%;
      transform: translateY(-50%);
      width: 50%;
    }


    .tab__inner {
      position: relative;
      display: inline-block;
      height: 100%;
      width: 100%;


      .tab__center  {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: rem(250);
        width: 100%;

        &:before {
          transition-property: height;
          transition-duration: .5s;
          transition-timing-function: ease, ease;
          transition-delay: .5s;
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: rem(80);
          width: 110%;
          background: url('../img/ui/tab-link-lines.svg');
          background-repeat: repeat-x;
          background-position: center;
          z-index: -1;
        }

        span {
          transition: border-color, border-width;
          transition-duration: .3s;
          transition-delay: 0s;
          position: relative;
          padding: rem(2) rem(5);
          border-width: 0 0 0 0;
          border-style: solid;
          border-color: rgba($yellow, 0);
        }
      }

    }
  }

  li:hover .tab__inner {
    .tab__center span {
      border-color: rgba($yellow, 1);
      transition-delay: .5s, .5s;
    }
  }

  li:hover .tab__inner,
  li.is-active .tab__inner {
    .tab__center span {
      border-width: 0 0 2px 0;
    }
    .tab__center:before {
      height: 0;
      transition-delay: 0s;
    }

  }

  li.is-active .tab__inner {
    .tab__center:before {
      transition-delay: 0s, 0s;
      top: 50%;
      bottom: 50%;
    }
    .tab__center span {
      border-color: $white;
    }
  }

}


.a-tab-panel {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  &[aria-hidden="true"] {
    display: none;
  }
}

.a-tab-panel__left,
.a-tab-panel__right {
  @include above($mobile) {
    position: absolute;
  }

  &,
  p {
    $fs: 16;
    @extend %fw-medium;
    font-size: rem($fs);
    line-height: em(26, $fs);
  }
}

.a-tab-panel__left {
  @include above($mobile) {
    top: rem(200);
    left: rem(40);
    max-width: rem(400);
  }

  .a-fact {
    text-align: left;

    &__result {
      line-height: 1.4;
    }

    &__description {
      @extend %ff-sans;
      text-transform: none;
    }
  }

  .btn {
    margin-top: rem(40);
  }
}


.a-tab-panel__right {
  @include above($mobile) {
    right: 10%;
    bottom: rem(40);
    max-width: rem(320);
  }

  @include above($xlarge) {
    bottom: auto;
    top: 75%;
    transform: translateY(-50%);
  }
}



#o-project--toeristische-onderzoeken {
  .a-tab-panel__left {
    @include above($mobile) {
      top: rem(140);
    }
  }
}

#panel-kustonderzoek {
  .a-tab-panel__right {
    @include above($xlarge) {
      top: 80%;
    }
  }
}
