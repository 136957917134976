.o-section--intro {
  opacity: 0;
  transition: all 0.3s;
  padding-bottom: 30vh;
  @include above($mobile) {
    @include section-size;
  }

  &.show {
    opacity: 1;
  }

  .bg-img__inner {
    background-position: left center;

    @include above($mobile) {
      background-position: center;
    }
  }
}


.o-section__content--intro {
  @include above($mobile) {
    width: 50%;
    left: 50%;
  }
}

.o-section--intro {

  .o-section__content--intro {
    margin-top: -50px;
    opacity: 0;
    transition: all 0.6s 0.6s ease-out;
  }

  &.show .o-section__content--intro {
    margin-top: 0;
    opacity: 1;
  }
}





.a-intro-title {
  $fs: 32;
  $ti: 1.5em;

  font-size: rem(32);
  line-height: em(32, 32);
  padding: rem(40) 0 0 $ti;
  width: rem(325);
  margin: auto;

  span {
    display: block;
    text-indent: -$ti;
  }

  @include above($mobile) {
    $fs: 66;
    $ti: 1.6em;

    font-size: flexText($minFontSize: 30, $maxFontSize: 66, $minViewPort: 300, $maxViewPort: 1600);
    line-height: em(70,$fs);
    width: auto;
    padding-left: $ti;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -($ti + 0.06);

    span {
      font-size: em(69,$fs);

      text-transform: uppercase;
      text-indent: -$ti;
    }
  }
}



.a-intro-description {
  margin: auto;
  padding-left: 3em;
  max-width: rem(325);

  p {
    $fs: 16;
    font-size: rem($fs);
    line-height: em(26, $fs);
    margin: em(22, $fs) 0;
  }

  @include above($mobile) {

    margin: 0;
    padding-left: 0;
    max-width: rem(464);

    p {
      $fs: 18;
      font-size: rem($fs);
      line-height: em(29, $fs);
      margin: em(30, $fs) 0;
    }
  }


}


.a-intro-cta {
  $fs: 16;
  @extend %fw-medium;

  max-width: 190px;
  font-size: rem($fs);
  line-height: em(26, $fs);
  position: relative;

  & > span {
    display: block;
    line-height: 1.2em;
    font-size: rem(50);
    color: $black;
  }

  .icn {
    @include size(em(40, $fs));
    position: absolute;
    left: em(-34, $fs);
    top: -1px;
  }
}
