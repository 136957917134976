article {
  margin-top: -2px;
}
.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;

  &__mobile {
    display: block;
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: 0;
    right: 0;

    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 0;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: 0 0;
    }

    .bg-img__inner {
      z-index: -1;
      position: relative;

    }


    &:before {
      .o-section--intro & {
        background-image: url('../img/backgrounds/bg-intro.svg');

        @include above($mobile) {
          background-image: url('../img/backgrounds/bg-intro-lrg.svg');
        }
      }
      .template-1 & { background-image: url('../img/backgrounds/bg-template-1.svg'); }
      .template-2__section-a & { background-image: url('../img/backgrounds/bg-template-2a.svg'); }
      .template-2__section-b & { background-image: url('../img/backgrounds/bg-template-2b.svg'); }
      .template-3 & { background-image: url('../img/backgrounds/bg-template-3.svg'); }
      .template-4__section-a & { background-image: url('../img/backgrounds/bg-template-2a.svg'); }
      .template-4__section-b & { background-image: url('../img/backgrounds/bg-template-2b.svg'); }
    }

  }
}



canvas {
  display: none;

  // @include above($mobile) {
  //   //display: block;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   z-index: 1;
  // }
}

.is-visible {
  @include above($mobile){
    canvas {
      // display: block;
      display: none;
    }
  }
}


.ball {
  background: black;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  z-index: 10;
}
